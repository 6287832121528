<template>
    <b-row class="news__item news-item--big">
        <b-col cols="12"  md="6" lg="5" class="img-wrap">            
            <img  v-if="news.image && itemSrc(news, 'w1080h714') != 'no-image' && itemSrcset(news) != 'no-image'"
                sizes="(max-width: 767px) 100vw, 50vw" 
                :srcset="itemSrcset(news)"
                :src="src_url + '/' + itemSrc(news, 'w1080h714')" 
                class="w-100"
            />
            <!-- noimage -->
            <img v-else src="@/assets/images/noimages/noimage-news.jpg" class="w-100"/>
        </b-col>        
        <b-col cols="12" md="6" lg="7">
            <div class="news__item-inner">
                <div class="name">{{news.name}}</div>
                <div class="date">{{publishedDate}}</div>
                <div class="lead">{{news.short_description}}</div>
                <div class="btn-wrap">
                    <router-link v-if='isPromotion == true' :to='{ name: "PromoDetails", params:{ promo_slug: news.slug }}' 
                    type="button" class="btn btn--brown" @click.native="setDataLayer('details_click', 'promotion_click', news.name)">Részletek</router-link>
                    <router-link v-else :to='{ name: "NewsDetails", params:{ news_slug: news.slug } }' 
                    type="button" class="btn btn--brown"  @click.native="setDataLayer('details_click', 'news_click', news.name)">Részletek</router-link>
                </div>
            </div>
        </b-col>
    </b-row>
</template>

<script>
export default {
    props:['news', 'isPromotion'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    },
    computed:{
        publishedDate(){
            return this.getTimeDate(this.news.published_at)
        }
    }    
}
</script>