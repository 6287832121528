<template>
    <div class="news__item news-item--normal">
        <div class="news__item-img">
            <div class="news__item-img-inner">
                <img  v-if="news.image && itemSrc(news, 'w484h320') != 'no-image' && itemSrcset(news) != 'no-image'"
                    sizes="(max-width: 767px) 100vw, 50vw" 
                    :srcset="itemSrcset(news)"
                    :src="src_url + '/' + itemSrc(news, 'w484h320')"                 
                />
                <!-- noimage -->
                <img v-else src="@/assets/images/noimages/noimage-news-little.jpg" />
            </div>
        </div>
        <div class="news__item-data">
            <div class="news__item-inner">
                <div class="name">{{news.name}}</div>                
                <div class="lead">{{news.short_description}}</div>
                <div class="btn-wrap">
                    <router-link :to='{ name: "NewsDetails", params:{ news_slug: news.slug } }' type="button" class="btn--text-link" @click.native="setDataLayer('details_click', 'news_click', news.name)">Részletek</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props:['news'],
    data(){
        return{
            src_url: process.env.VUE_APP_BIFROST_API_URL_NO_PROJECTNAME
        }
    }    
}
</script>